import React, { useRef, useEffect, useState } from "react";

import './firstpage.css'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/footer'
import { useParams } from 'react-router-dom';
// import TOPlHRContent from '../blog/blogs-content/top-lahore';
// import GiftsContent from '../blog/blogs-content/gifts';
// import BahriaContent from '../blog/blogs-content/bahriatown'
// import JoharTown from '../blog/blogs-content/johartown'
import VoiceCommand from '../blog/blogs-content/voiceCommand'
import Gift from '../blog/blogs-content/gifts'
import FpSucess from '../blog/blogs-content/fpSucess'




function Firstpage() {
    const { blog } = useParams();
    let ContentComponent;

    switch (blog) {
        case 'Voice-Command-Food-Ordering':
            ContentComponent = VoiceCommand;
            break;
        case 'Spreading-Joy-&-Gift-at-a-Time':
            ContentComponent = Gift;
            break;
        case 'ForunPoint-Blueprint-for-Success':
            ContentComponent = FpSucess;
            break;
    }

    return (
        <div>
            <Navbar />
            <ContentComponent />
            <Footer />
        </div>
    )
}

export default Firstpage
