// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import HomePage from "./componentes/home/homepage";
// import Footer from "./componentes/Footer/footer";
// import '@fortawesome/fontawesome-free/css/all.css';
// import Facebook from "./componentes/tracking/Facebook";
// import LandingGif from "./componentes/landing-gif/landing-gif";

// export const ConvertPKRtoUSD = (price) => {
//   const userCountry = localStorage.getItem("country");

//   if (userCountry === 'US') {
//     return `$${price}`;

//   }
//   else if (userCountry === 'CA') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'GB') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'AU') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'AE') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else if (userCountry === 'SA') {
//     return `Rs ${Math.round(price)}`;
//   }
//   else {
//     return `Rs ${Math.round(price)}`;
//   }
// };

// function App() {
//   const [location, setLocation] = useState(localStorage.getItem('country'));
//   const [error, setError] = useState(null);
//   const [convertedPrice, setConvertedPrice] = useState(null);

//   useEffect(() => {
//     const fetchLocation = async () => {
//       try {
//         const response = await axios.get(`https://ipinfo.io/json?token=6e6f5105a4e867`);
//         const country = response.data.country;
//         const storedCountry = localStorage.getItem('country');
//         if (storedCountry !== country) {
//           localStorage.setItem('country', country);
//           setLocation(country);
//         }
//       } catch (error) {
//         setError('Could not determine location.');
//       }
//     };
//     fetchLocation();
//   }, []);

//   useEffect(() => {
//     const convertPrice = () => {
//       const price = 1000;
//       const result = ConvertPKRtoUSD(price);
//       setConvertedPrice(result);
//     };

//     if (location) {
//       convertPrice();
//     }
//   }, [location]);

//   if (error) {
//     return <div>{error}</div>;
//   }

//   if (location === null) {
//     return null;
//   }

//   if (location === 'US' || location === 'PK' || location === 'CA' || location === 'GB' || location === 'AU' || location === 'AE' || location === 'SA') {
//     return (
//       <div>
//         <Facebook />
//         <LandingGif />
//         <HomePage />
//         <Footer />
//       </div>
//     );
//   } else {
//     return (
//       <div style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100vh',
//         textAlign: 'center',
//       }}>
//         <div>
//           <p style={{
//             padding: '20px',
//             borderRadius: '10px',
//             color: '#333',
//             fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
//             fontSize: '18px',
//             color: '#d4110f',
//             fontSize: 'calc(10px + 1vw)',
//             fontWeight: '500'
//           }}>
//             Sorry...!
//             <br />
//             We are not offering our services in your country.
//           </p>
//         </div>
//       </div>
//     );
//   }
// }

// export default App;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import HomePage from "./componentes/home/homepage";
import SubFooter from './componentes/sub-footer/sub-footer';
import Footer from "./componentes/Footer/footer";
import '@fortawesome/fontawesome-free/css/all.css';
import Facebook from "./componentes/tracking/Facebook";
import LandingGif from "./componentes/landing-gif/landing-gif";
import { Navbar } from 'react-bootstrap';
import Navbarr from './componentes/Navbar/Navbar';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const ConvertPKRtoUSD = (price) => {
  const userCountry = localStorage.getItem("country");

  if (userCountry === 'US' || userCountry === 'CA' || userCountry === 'AU') {
    return `$${price}`;
  } else if (userCountry === 'GB') {
    return `£${(price)}`;
  } else if (userCountry === 'AE') {
    return `AED${(price)}`;
  }
  else if (userCountry === 'SA') {
    return `SAR${(price)}`;
    // Math.round
  }
};

function App() {
  const [location, setLocation] = useState(localStorage.getItem('country'));
  const [error, setError] = useState(null);
  const [convertedPrice, setConvertedPrice] = useState(null);

  const fetchLocation = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`);
      const country = response.data.country;
      // const country = 'CA';
      const storedCountry = localStorage.getItem('country');
      if (storedCountry == 'PK') {
        window.location.href = "https://forunpoint.com.pk";
      }
      // console.log(country)
      if (storedCountry !== country) {
        localStorage.setItem('country', country);
        setLocation(country);
      }
    } catch (error) {
      setError('Could not determine location.');
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    const convertPrice = () => {
      const price = 1000;
      const result = ConvertPKRtoUSD(price);
      setConvertedPrice(result);
    };

    if (location) {
      convertPrice();
    }
  }, [location]);

  if (error) {
    return <div>{error}</div>;
  }

  if (location === null) {
    return null;
  }
  if (location === 'US' || location === 'CA' || location === 'GB' || location === 'AU' || location === 'AE' || location === 'SA') {
    return (
      <div>
        <Facebook />
        <LandingGif />
        <GoogleOAuthProvider clientId="744506488642-5oa1cccmhv2lgi5io05j07h6c1eioaif.apps.googleusercontent.com">
          <HomePage />
        </GoogleOAuthProvider>
        <SubFooter />
        <Footer />
      </div>
    );
  } else {
    return (
      // <div style={{
      //   display: 'flex',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   height: '100vh',
      //   textAlign: 'center',
      // }}>
      //   <Navbarr/>
      //   <div>
      //     <p style={{
      //       padding: '220px',
      //       borderRadius: '10px',
      //       color: '#333',
      //       fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
      //       fontSize: '18px',
      //       color: '#d4110f',
      //       fontSize: 'calc(10px + 1vw)',
      //       fontWeight: '500',
      //     }}>
      //       Sorry...!
      //       <br />
      //       We are not offering our services in your country.
      //     </p>
      //   </div>
      //   <Footer />

      // </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
        {/* Navbar at the top */}
        <Navbarr />

        {/* Centered Content with extra space above and below */}
        <div style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          paddingTop: '100px',  // Extra space on top
          paddingBottom: '100px',  // Extra space on bottom
        }}>
          <p style={{
            padding: '50px',
            borderRadius: '10px',
            color: '#d4110f',
            fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
            fontSize: 'calc(10px + 1vw)',
            fontWeight: '500',
          }}>
            Sorry...!
            <br />
            We are not offering our services in your country.
          </p>
        </div>

        {/* Footer at the bottom */}
        <Footer />
      </div>

    );
  }
}

export default App;
